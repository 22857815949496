<template>
  <Layout>
    <div class="createdBanners">
      <div>
        <modal-upload></modal-upload>
        <div
          :class="{ editBannerClass: editBannerModal, hide: !editBannerModal }"
        >
          <div class="contentAdd">
            <svg
              @click="closeEditBanner"
              class="close"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 1.42603L1 16.426M1 1.42603L16 16.426"
                stroke="#B5B9C5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div class="title" v-if="!createnewbanner">Editar Banner</div>
            <div class="title" v-else>Criar Banner</div>
            <br />
            <div class="spaceInputs">
              <b-form-group label="Posição do Banner" label-for="name-module">
                <b-form-select v-model="posicaoBanner">
                  <b-form-select-option value="cima"
                    >Acima</b-form-select-option
                  >
                  <b-form-select-option value="baixo"
                    >Abaixo</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </div>
            <div class="spaceInputs">
              <b-form-group
                label="Link do Banner"
                label-for="name-module"
              >
                <b-form-input
                  v-model="linkBanner"
                  placeholder="Link do Banner"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Banner Condicional" label-for="name-module">
                <toggle-button
                  :sync="true"
                  @change="updateMetaCondicional"
                  v-model="condicionalBanner"
                />
              </b-form-group>
              <div v-if="condicionalBanner">
                <b-form-group label="Tipo do Banner" label-for="name-module">
                  <b-form-select
                    v-model="tipoBannerCondicional"
                    @change="zeraSelect()"
                  >
                    <b-form-select-option value="course_class"
                      >Turmas</b-form-select-option
                    >
                    <b-form-select-option value="signature"
                      >Assinaturas</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  v-if="tipoBannerCondicional === 'course_class'"
                  label="Turmas"
                  label-for="name-module"
                >
                  <multiselect
                    noOptions="Nenhuma Turma Disponivel"
                    placeholder="Turmas"
                    selectedLabel=""
                    deselectLabel=""
                    tagPlaceholder=""
                    selectLabel=""
                    track-by="id"
                    :multiple="false"
                    :taggable="false"
                    :custom-label="customLabel"
                    v-model="classDesassoc"
                    :options="AllProducts"
                    @open="getAllClass"
                  >
                    <template slot="afterList">
                      <div v-observe-visibility="reachedEndOfList3" />
                    </template>
                  </multiselect>
                </b-form-group>

                <b-form-group
                  v-if="tipoBannerCondicional === 'signature'"
                  label="Assinaturas"
                  label-for="name-module"
                >
                  <multiselect
                    placeholder="Assinaturas"
                    selectedLabel=""
                    deselectLabel=""
                    tagPlaceholder=""
                    selectLabel=""
                    track-by="id"
                    :multiple="false"
                    :taggable="false"
                    :custom-label="customLabel"
                    v-model="signatureDesassoc"
                    :options="AllSignature"
                    @open="getAllSignature"
                  >
                    <template slot="afterList">
                      <div v-observe-visibility="reachedEndOfList" />
                    </template>
                  </multiselect>
                </b-form-group>
              </div>
            </div>
            <div class="spaceInputs">
              <b-form-group label="Banner (Opcional)" label-for="capa">
                <p class="textDimenssion">
                  Tamanho recomendado: 1680 x 420px ou proporcional.
                </p>
                <div
                  class="btn-openModal"
                  @click="showModalUploadBanner"
                  v-if="midiacapamodule === '' || midiacapamodule === null"
                >
                  <p class="text">Clique e faça o Upload do Banner</p>
                </div>
                <div
                  class="btn-openModalPreview"
                  @click="showModalUploadBanner"
                  v-else
                >
                  <img
                    class="imagePreviewCapa animationOpacity"
                    :src="midiacapamoduleurl"
                    :alt="midiacapamodule"
                  />
                </div>
              </b-form-group>
            </div>
            <div class="spaceInputs">
              <b-form-group label="Banner Mobile (Opcional)" label-for="capa">
                <p class="textDimenssion">
                  Tamanho recomendado: 720 x 400px ou proporcional.
                </p>
                <div
                  class="btn-openModal"
                  @click="showModalUploadBannerMobile"
                  v-if="
                    midiacapamodulemobile === '' ||
                      midiacapamodulemobile === null
                  "
                >
                  <p class="text">Clique e faça o Upload do Banner</p>
                </div>
                <div
                  class="btn-openModalPreview"
                  @click="showModalUploadBannerMobile"
                  v-else
                >
                  <img
                    class="imagePreviewCapa animationOpacity"
                    :src="midiacapamodulemobileurl"
                    :alt="midiacapamodulemobile"
                  />
                </div>
              </b-form-group>
            </div>
            <div class="space">
              <button class="btn-salvar" @click="salvarBanner">Salvar</button>
            </div>
          </div>
        </div>
        <b-row class="spaceWizzard">
          <b-col cols="12" md="3" class="pt-5" data-anima="top"> </b-col>
          <b-col cols="12" md="12" class="pt-5">
            <div class="containerWizzard">
              <div class="title d-flex flex-row justify-content-center">
                <p class="m-0">Gerenciamento de Banners</p>
                <IconSax class="ml-2" name="copy"/>
              </div>
            </div>
            <div class="containerWizzardInputs">
              <draggable
                :list="AllBanners"
                :disabled="!enabled"
                class="list-group"
                ghost-class="ghost"
                @end="onEnd"
              >
                <div
                  class="spaceBannerCard"
                  v-for="(banner, index) in AllBanners"
                  :key="banner.id"
                  :data-id="banner.id"
                >
                  <div
                    class="actionBanner d-flex align-items-center p-3"
                    data-anima="top"
                    :id="'firstmodule' + index"
                  >
                    <div class="actionBanner">
                      <div
                        class="titleBanner"
                        :id="'modulename' + index"
                        @click="editBanner(banner)"
                      >
                        <img
                          class="imgBanner"
                          width="50"
                          :src="banner.media?.cdn_url || banner.mobile_media?.cdn_url"
                          alt="imagem"
                        />
                      </div>
                    </div>
                    <div class="p-0 d-flex align-items-center justify-content-center">
                      <b-dropdown
                        id="dropdown-dropright"
                        dropright
                      >
                      <template #button-content>
                        <IconSax name="edit" size="1rem" />
                      </template>
                        <b-dropdown-item @click="editBanner(banner)"
                          >Editar Banner</b-dropdown-item
                        >
                        <b-dropdown-item @click="deleteBanner(banner.id)"
                          >Deletar Banner</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </draggable>
              <div class="spaceInputs">
                <b-form-group class="paddingNewBanner">
                  <div
                    class="btn-newBanner"
                    @click="openModalToaddNew()"
                    id="step11"
                  >
                    <p class="text">Adicionar mais um Banner</p>
                  </div>
                </b-form-group>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "@/layout/Layout.vue";
import Slidebar from "@/components/Slidebar.vue";
import ModalUpload from "@/components/ModalUpload.vue";
// import Vue2Filters from "vue2-filters";
import draggable from "vuedraggable";
import BannerService from "@/services/resources/BannerService";
const serviceBanner = BannerService.build();
import notify from "@/services/libs/notificacao";
import Multiselect from "vue-multiselect";
import ClassService from "@/services/resources/ClassService";
import SignatureService from "@/services/resources/SignatureService";

const serviceSignature = SignatureService.build();
const serviceClass = ClassService.build();

export default {
  // mixins: [Vue2Filters.mixin],
  components: {
    Layout,
    Slidebar,
    ModalUpload,
    draggable,
    Multiselect,
  },
  data() {
    return {
      enabled: true,
      AllBanners: [],
      editBannerModal: false,
      midiacapamodule: null,
      midiacapamoduleurl: null,
      midiacapamodulemobile: null,
      midiacapamodulemobileurl: null,
      createnewbanner: false,
      linkBanner: "",
      tipoBanner: "link",
      idBannerEdit: "",
      AllProducts: [],
      productAssing: null,
      pageActualProduct: 1,
      lastPageProduct: 1,
      condicionalBanner: false,
      pageActualSignature: 1,
      lastPageSignature: 1,
      AllSignature: [],
      signatureDesassoc: null,
      classDesassoc: null,
      tipoBannerCondicional: "signature",
      posicaoBanner: "cima",
    };
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
  },
  methods: {
    reachedEndOfList(reached) {
      if (reached) {
        this.addNextPageAssing();
      }
    },
    addNextPageAssing() {
      if (this.lastPageSignature >= this.pageActualSignature) {
        this.pageActualSignature = this.pageActualSignature + 1;
        this.getAllSignature("push");
      }
    },
    getAllSignature(action) {
      this.$root.$emit("loadOn");
      if (this.pageActualSignature === null) {
        this.pageActualSignature = 1;
      }

      var url = "page=" + this.pageActualSignature;
      serviceSignature
        .search(url)
        .then((resp) => {
          //console.log("todas as assinaturas", resp);
          var data = resp.data;
          this.pageActualSignature = resp.current_page;
          this.lastPageSignature = resp.last_page;
          if (action === "push") {
            let list = resp.data;
            for (let i = 0; i < list.length; i++) {
              const element = list[i];
              this.AllSignature.push(element);
            }
          } else {
            this.AllSignature = data;
          }
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    async getAllClassSelected() {
      await this.zeraSelect();
      this.getAllClass();
    },
    zeraSelect() {
      this.pageActualSignature = 1;
      this.lastPageSignature = 1;
      this.AllSignature = [];

      this.AllProducts = [];
      this.pageActualProduct = 1;
      this.lastPageProduct = 1;
    },
    updateMetaCondicional() {
      //console.log(this.condicionalBanner);
    },
    customLabel({ title }) {
      return `${title}`;
    },
    customLabel2({ name }) {
      return `${name}`;
    },
    getAllClass(action) {
      this.$root.$emit("loadOn");
      if (this.pageActualProduct === null) {
        this.pageActualProduct = 1;
      }
      var url = "page=" + this.pageActualProduct;
      serviceClass
        .search(url)
        .then((resp) => {
          //console.log("todos os produtos", resp);
          var data = resp.data;
          this.pageActualProduct = resp.current_page;
          this.lastPageProduct = resp.last_page;
          if (action === "push") {
            let list = resp.data;
            for (let i = 0; i < list.length; i++) {
              const element = list[i];
              this.AllProducts.push(element);
            }
          } else {
            this.AllProducts = data;
          }
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    reachedEndOfList3(reached) {
      if (reached) {
        this.addNextPageProducts();
      }
    },
    addNextPageProducts() {
      if (this.lastPageProduct >= this.pageActualProduct) {
        this.pageActualProduct = this.pageActualProduct + 1;
        this.getAllClass("push");
      }
    },
    closeEditBanner() {
      this.linkBanner = "";
      this.midiacapamodule = null;
      this.midiacapamoduleurl = null;
      this.midiacapamodulemobile = null;
      this.midiacapamodulemobileurl = null;
      this.editBannerModal = false;
    },
    editBanner(data) {
      //console.log("xxxxxxxxxxxx", data);
      this.idBannerEdit = data.id;
      this.getMetas(data.id);
      this.linkBanner = data.link;
      if (data.media) {
        this.midiacapamodule = data.media.id;
        this.midiacapamoduleurl = data.media.cdn_url;
      }
      if (data.mobile_media) {
        this.midiacapamodulemobile = data.mobile_media_id;
        this.midiacapamodulemobileurl = data.mobile_media.cdn_url;
      }
      this.editBannerModal = true;
      this.createnewbanner = false;
    },
    openModalToaddNew() {
      this.linkBanner = "";
      this.midiacapamodule = null;
      this.midiacapamoduleurl = null;
      this.midiacapamodulemobile = null;
      this.midiacapamodulemobileurl = null;
      this.editBannerModal = true;
      this.createnewbanner = true;
    },
    showModalUploadBanner() {
      this.$root.$emit("uploadbanner");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUploadBannerMobile() {
      this.$root.$emit("uploadbannermobile");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    onEnd: function(e) {
      //console.log("event", e);

      var array = [];
      var lessons = document.querySelectorAll(".spaceBannerCard");
      for (let i = 0; i < lessons.length; i++) {
        const lessonId = lessons[i].getAttribute("data-id");
        array.push(parseInt(lessonId));
      }
      let data = {
        id: "order",
        array,
      };

      this.$root.$emit("loadOn");
      serviceBanner
        .postIDArray(data)
        .then((resp) => {
          //console.log("order banner", resp, array);
          notify("sucesso", "Ordenação Salva!");
          this.getBanners();
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          notify("erro", "Erro ao Salvar Ordenação!");
          this.$root.$emit("loadOff");
        });
    },
    deleteBanner(id) {
      this.$root.$emit("loadOn");
      serviceBanner
        .destroy(id)
        .then((resp) => {
          //console.log("delete banner", resp);
          this.getBanners();
          notify("sucesso", "Banner deletado com Sucesso!");
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          notify("erro", "Erro ao Deletar Banner!");
          this.$root.$emit("loadOff");
        });
    },
    getMetas(id) {
      let data =
        id +
        "/meta?keys[]=type_banner&keys[]=product_banner&keys[]=conditional_banner&keys[]=position_banner&keys[]=item_banner_conditional&keys[]=type_banner_conditional";
      serviceBanner
        .read(data)
        .then((resp) => {
          //console.log("set metas banner", resp);
          if (resp.type_banner_conditional) {
            this.tipoBannerCondicional = resp.type_banner_conditional;
            if (resp.type_banner_conditional === "signature") {
              this.signatureDesassoc = JSON.parse(resp.item_banner_conditional);
            } else {
              this.classDesassoc = JSON.parse(resp.item_banner_conditional);
            }
          }

          if (resp.conditional_banner === "on") {
            this.condicionalBanner = true;
          } else {
            this.condicionalBanner = false;
          }

          if (resp.position_banner) {
            this.posicaoBanner = resp.position_banner;
          }
          if (resp.type_banner === "link") {
            return;
          } else {
            this.tipoBanner = resp.type_banner;
            if (resp.product_banner) {
              this.productAssing = JSON.parse(resp.product_banner);
            }
          }
          
          // this.condicionalBanner = resp.conditional_banner;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    setMetas(id) {
      var item = "signature";
      if (this.tipoBannerCondicional === "signature") {
        item = this.signatureDesassoc;
      } else if (this.tipoBannerCondicional === "course_class") {
        item = this.classDesassoc;
      } else {
        item = "";
      }

      let onff = "off";
      if (this.condicionalBanner) {
        onff = "on";
      }

      let data = {
        id: id + "/meta",
        position_banner: this.posicaoBanner,
        type_banner_conditional: this.tipoBannerCondicional,
        item_banner_conditional: JSON.stringify(item),
        type_banner: this.tipoBanner,
        conditional_banner: onff,
        product_banner: JSON.stringify(this.productAssing),
      };
      serviceBanner
        .postID(data)
        .then((resp) => {
          //console.log("set metas banner", resp);
        })
        .catch((err) => {
          //console.log(err);
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
        });
    },
    salvarBanner() {
      if (this.createnewbanner === true) {
        let data = {
          link: this.linkBanner,
          media_id: this.midiacapamodule,
          mobile_media_id: this.midiacapamodulemobile,
        };
        this.$root.$emit("loadOn");
        serviceBanner
          .create(data)
          .then((resp) => {
            //console.log("created module", resp);
            this.setMetas(resp.id);
            notify("sucesso", "Banner criado com Sucesso!");
            this.closeEditBanner();
            this.getBanners();
            this.$root.$emit("loadOff");
          })
          .catch((err) => {
            //console.log(err);
            var error = JSON.parse(err.response.data);
            var msg = "";
            for (var indice in error) {
              msg += error[indice][0] + "<br>";
            }
            if (msg !== "") {
              notify("erro", msg);
            }
            this.$root.$emit("loadOff");
          });
      } else {
        let data = {
          id: this.idBannerEdit,
          link: this.linkBanner,
          media_id: this.midiacapamodule,
          mobile_media_id: this.midiacapamodulemobile,
        };
        this.setMetas(this.idBannerEdit);
        this.$root.$emit("loadOn");
        serviceBanner
          .postID(data)
          .then((resp) => {
            //console.log("created module", resp);
            notify("sucesso", "Banner atualzado com Sucesso!");
            this.closeEditBanner();
            this.getBanners();
            this.$root.$emit("loadOff");
          })
          .catch((err) => {
            //console.log(err);
            var error = JSON.parse(err.response.data);
            var msg = "";
            for (var indice in error) {
              msg += error[indice][0] + "<br>";
            }
            if (msg !== "") {
              notify("erro", msg);
            }
            this.$root.$emit("loadOff");
          });
      }
    },
    getBanners() {
      serviceBanner
        .search()
        .then((resp) => {
          //console.log("get all banners", resp);
          this.AllBanners = resp;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
  mounted() {
    this.getBanners();
    this.$root.$on("newuploadbanner", (data) => {
      //console.log(data, "chegou aquiiiiii");
      this.midiacapamodule = data.id;
      this.midiacapamoduleurl = data.url;
    });
    this.$root.$on("newuploadbannermobile", (data) => {
      //console.log(data, "chegou aquiiiiii");
      this.midiacapamodulemobile = data.id;
      this.midiacapamodulemobileurl = data.url;
    });
  },
};
</script>
<style lang="scss">
.createdBanners {
  height: 100%;
  .removeLink {
    text-decoration: none;
  }
  .navigate {
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: var(--fontcolor) !important;
    }
    .lineDiv {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 0.5px;
      background: #ededf0;
    }
    .spaceBtn {
      padding: 4px;
      margin-top: 10px;
      .btnDesativar {
        background: rgba(0, 35, 99, 0.1);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        border: none;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #002363;
        height: 45px;
        width: 100%;
      }
    }
    .spaceNav {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      transform: translateY(0px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
      .text {
        font-family: Inter;
        font-style: normal;
        font-size: 15px;
        color: #81858e;
      }
    }
    .spaceNav:hover {
      transform: translateY(2px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
    }
  }

  .editBannerClass {
    position: fixed;
    background: var(--fundomodal);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    .contentAdd {
      z-index: 99999;
      position: absolute;
      padding: 30px;
      right: 0;
      top: 0;
      width: 450px;
      height: 100%;
      background: var(--backgroundcolor);
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 5px 0px 0px 5px;
      overflow: auto;
      .close {
        cursor: pointer;
      }
      .space {
        margin-top: 40px;
        margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #333;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 30px;
          border: none;
          width: 146px;
          font-family: Inter;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
      }
    }
  }
  .hide {
    display: none !important;
  }
  .spaceWizzard {
    .viewAulas {
      .flexAula {
        display: flex;
        justify-content: space-between;
        .flex {
          display: flex;
          .action {
            position: relative;
            top: 8px;
          }
          .play {
            position: relative;
            top: 6px;
          }
        }
      }
      .textAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
        margin-left: 20px;
      }
      .timeAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
      }
      .actionAula {
        display: flex;
        justify-content: space-between;
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Inter !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
    }
    .blur1 {
      position: fixed;
      left: 1px;
      bottom: -60px;
    }
    .blur2 {
      position: fixed;
      right: 1px;
      top: 80px;
      transform: rotate(180deg);
    }
    .spaceInputsCreate {
      display: flex;
      justify-content: flex-end;
      position: relative;
      left: 5em;
      top: -2em;
      .btn-criar {
        background: var(--maincolor);
        border: 1px solid var(--maincolor);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        width: 100%;
        height: 55px;
        margin-top: 40px;
        width: 250px;
      }
    }
    .containerWizzardInputs {
      margin-left: auto;
      margin-right: auto;
      width: 95%;
      margin-top: 50px;
      .paddingNewBanner {
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 10px;
      }
      .btn-newBanner {
        background: rgba(129, 133, 142, 0.03);
        border: 2px dashed #81858e;
        box-sizing: border-box;
        border-radius: 30px;
        height: 55px;
        cursor: pointer;
        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #81858e;
          display: flex;
          justify-content: center;
          margin-top: 17px;
        }
      }
      .removemb {
        margin-bottom: 0px !important;
      }
      .actionBanner {
        display: flex;
        justify-content: space-between;
        .titleBanner {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: var(--maincolor);
          line-height: 3em;
        }
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Inter !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
      .lineDiv {
        background: #ededf0;
        transform: matrix(1, 0, 0, -1, 0, 0);
        height: 0.5px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .spaceBannerCard {
        width: 100%;
        background: var(--backgroundcolor);
        border: 0.5px solid var(--bordercolor);
        box-sizing: border-box;
        border-radius: 30px;
        margin-bottom: 20px;
        cursor: pointer;
      }
    }
    .containerx {
      width: 98%;
    }
    .containerWizzard {
      text-align: center;
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        align-items: center;
        color: var(--fontcolor) !important;
      }
    }
  }
  .spaceInputs {
    margin-bottom: 30px;
    .textInfo {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #81858e;
      margin-top: 5px;
    }
    .btn-criar {
      background: var(--maincolor);
      border: 1px solid var(--maincolor);
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      border-radius: 30px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      width: 100%;
      height: 55px;
      margin-top: 40px;
    }
    .btn-openModal {
      background: var(--maincolortrans);
      border: 2px dashed var(--maincolor);
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      cursor: pointer;
      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
        display: flex;
        justify-content: center;
        margin-top: 17px;
      }
    }
    .gerenciarCat {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.2px;
      color: var(--maincolor);
      position: absolute;
      right: 25em;
      cursor: pointer;
    }
    select {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #6d767e;
      overflow: hidden !important;
      resize: none !important;
    }
    input,
    textarea {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      overflow: hidden !important;
      resize: none !important;
      background: white;
    }
    input:hover,
    select:hover,
    textarea:hover,
    input:focus,
    select:focus,
    textarea:focus {
      border: 1px solid var(--maincolor);
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
    }
    label {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--fontcolor);
    }
  }
}

.imgBanner {
  width: 130px;
  height: 60px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 3px;
}

.btn-openModalPreview {
  cursor: pointer;
  img {
    border-radius: 3px;
  }
}

.actions svg {
  margin-top: 35px;
}
</style>
